<template>
  <div>
    <b-row>
      <b-col cols="3">
        <h4 class="mb-0">{{ name }}</h4>
        <p class="mb-0">({{ id }})</p>
      </b-col>
      <b-col cols="9">
        <div
          v-if="!hasWriteAccess"
        >
          <div v-if="fieldValue" v-html="fieldValue"></div>
          <p v-else>Field empty</p>
        </div>
        <froala
          v-else
          ref="froalaEditor"
          :tag="'textarea'"
          :config="config"
          v-model="fieldValue"
          :disabled="!hasWriteAccess"
        ></froala>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import 'froala-editor/js/plugins.pkgd.min';
import VueFroala from 'vue-froala-wysiwyg';
import FroalaEditor from 'froala-editor';

export default {
  name: 'RichTextField',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    VueFroala,
  },
  props: {
    name: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    hasWriteAccess: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      config: {
        events: {
          contentChanged: this.onContentChanged,
        },
        tooltips: true,
        height: 300,
        toolbarInline: false,
        key: 'jUA1yC3C4E2F2A2E1A1qYFa1UQRFQIVc2MSMd1IWPNb1IFd1yD2I2D1B1C7E2E1C5G1G2==',
        attribution: false,
        paragraphFormat: {
          H6: 'H6',
          H5: 'H5',
          H4: 'H4',
          H3: 'H3',
          H2: 'H2',
          H1: 'H1',
          N: 'Normal',
        },
        toolbarSticky: false,
        linkInsertButtons: ['linkBack'],
        linkText: true,
        pastePlain: true,
        wordPasteKeepFormatting: false,
        wordPasteModal: false,
        wordAllowedStyleProps: [],
        enter: FroalaEditor.ENTER_BR,
        linkConvertEmailAddress: true,
        linkAutoPrefix: '',
        paragraphFormatSelection: true,
        linkMultipleStyles: true,
        quickInsertEnabled: false,
        charCounterCount: false,
        fontSize: ['4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '55', '60', '70', '80', '96', '108', '120', '140'],
        lineHeights: {
          Default: '',
          0.5: '0.5',
          1: '1',
          1.15: '1.15',
          1.3: '1.3',
          1.5: '1.5',
          2: '2',
          3: '3',
        },
        // language: self.options.language || 'en',
        toolbarButtons: {
          moreText: {
            buttons: ['bold', 'textColor', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontSize', 'clearFormatting'],
            buttonsVisible: 2,
          },
          moreParagraph: {
            buttons: ['alignLeft', 'alignCenter', 'paragraphFormat', 'formatULSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'lineHeight', 'paragraphStyle'],
            buttonsVisible: 3,
          },
          moreRich: {
            buttons: ['insertLink', 'insertTable', 'fontAwesome', 'emoticons', 'specialCharacters', 'embedly', 'br', 'line'],
            buttonsVisible: 1,
          },
          moreMisc: {
            buttons: ['undo', 'redo', 'help'],
            align: 'right',
            buttonsVisible: 3,
          },
        },
      },
      fieldValue: null,
    };
  },
  created() {
    this.fieldValue = this.value;
  },
  watch: {
    fieldValue(newValue, oldValue) {
      if (newValue !== oldValue && newValue !== this.value) {
        this.$emit('update', newValue);
      }
    },
  },
  methods: {
    onContentChanged() {
      const froalaEditor = this.$refs.froalaEditor.getEditor();
      const content = froalaEditor.html.get();
      this.fieldValue = content;
    },
  },
};
</script>

<style scoped lang="scss">
.personalization-settings #content input {
  color: #495057;
  border: 1px solid #ced4da;
  background-color: #fff;
}

h4, p {
  font-size: 15px;
}

h4 {
  font-weight: 600;
}

/deep/ .fr-toolbar.fr-top, /deep/ .fr-second-toolbar {
  border-radius: 0;
}

/deep/ .fr-element {
  background-color: #eeeeee;
}

/deep/ h1,
/deep/ h2,
/deep/ h3,
/deep/ h4,
/deep/ h5,
/deep/ h6,
/deep/ p{
  color:black !important;
  font-size:18px !important;
}

</style>
